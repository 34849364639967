<template>
  <div>Index</div>
</template>

<script>
export default {
  name: "Index",
};
</script>

<style>
</style>